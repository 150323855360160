.procedure_background {
    background-color: rgb(168, 169, 169);
}

.procedure_dental_service {
    text-align: center;
    padding-top: 20px;
}

.procedure_ul_list {
    display: inline;
}

.procedure_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
}

.procedure_container-item {
    margin: 30px;
    margin-bottom: 30px;
    width: 400px;
    background-color: lightgray;
    border: 1px solid black;
}

.procedure_container_crowns_bridges {
    min-height: 100px;
}

.procedure_container_restorations {
    min-height: 200px;
}

.procedure_container_extractions {
    min-height: 300px;
}

.procedure_container_dentures {
    min-height: 100px;
}

.procedure_container_partials {
    min-height: 200px;
}

.procedure_container_endodontic {
    min-height: 300px;
}

.procedure_container-item:hover {
    box-shadow: 0 4px 8px 0 lightgray, 0 6px 20px 0 lightgray;
}