.footer-container {
    background: lightblue;
    padding: 0rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
    padding-bottom: 30px;
    padding-top: 30px;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
    text-align: center;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}


.footer-link-items p {
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: white;
}