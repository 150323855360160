.procedure_card_container {
    padding-top: 15px;
    text-align: center;
}

.procedure_card_image_container {
    display: flex;
    justify-content: center;
}

.procedure_card_image {
    border-radius: 50%;
    height: 200px;
    object-fit: contain;
    padding-top: 5px;
}

.procedure_card_title {
    padding-bottom: 5px;
}

.procedure_card_about {
    padding-inline: 50px;
    padding-bottom: 10px;
}