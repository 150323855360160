.about-container {
    background-color: lightgray;
}

.about_img_container {
    padding-top: 30px;
}

.about_welcome_container {
    padding: 30px;
}

.about_welcome_title {
    text-align: center;
}

.about_paragraph {
    padding-inline: 200px;
    padding-bottom: 30px;
    line-height: 1.5;
}

.about_pic {
    margin: auto;
    display: block;
    border: 1px solid black;
    width: 50%;
    border-radius: 50%;
}

@media only screen and (max-width: 1024px) {
    .about_paragraph {
        padding-inline: 50px;
    }
}