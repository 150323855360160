* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Times New Roman", Times, serif;
}

:root {
    --mainColor: lightblue;
    --mainColorLight: grey;
    --secondColor: white;
    --textColor: black;
}


header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    position: sticky;
    top: 0;
}

nav a{
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
    font-weight: bold;
}

nav a:hover{
    color: var(--secondColor);
}

header .navbar-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

header div,
nav {
    display: flex;
    align-items: center;
}

.dentalicon {
    width: 15%;
    border-radius: 50%;
    cursor: pointer;
}

html {
    scroll-behavior: smooth;
}

@media only screen and (max-width: 1024px) {
    header .navbar-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive_nav {
        transform: none;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }
    
    .dentalicon {
        width: 95px;
    }
}