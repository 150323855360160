.header-container {
    display: flex;
    background-color: lightgray;
    align-content: center;
    justify-content: center;
}

.header-container div {
    align-items: center;
    margin: 10px;
    width: 100%;
    padding: 20px;
    text-align: center;
    border: 2px solid black
}

a {
    text-decoration: none;
    color: black;
}


@media only screen and (max-width: 1024px) {
    span.text {
        display: none;
    }
}